import { $ } from "../third-party-imports";

let wkts:any = [];
let mapType: any = "";
let centroid: any = "";

$(function ()
{
    setTimeout(function ()
    {
        if ($(".map-container").length > 0) {
            $('.map-container').append('<div class = "gmap-loader"></div>');
            getwellKnownText();
        }
    }, 1000);
});

function multipleWkts(){
    wkts.forEach((wkt: any) => {
        if (mapType === "polygon") {
            initGMapPolygon();
        } else {
            initGMap(wkt);
        }
    });
}
function initGMap(wkt:any){
    const geometry = Terraformer.WKT.parse(wkt);
    // var center = geometry?.coordinates[0][0][0]; // bug fix 111641
    const center = centroid.replace("POINT (", "").replace(')', "").split(" "); // bug fix 111641
    const data = {
        "type": "FeatureCollection",
            "features": [ {
            "type": "Feature",
                "properties": {
                "fillColor": "#1E90FF"
            },
                "geometry": geometry
        }]
    };
    let zoomLevel;
    if($("#hdn_zoomLevel").val()){
        zoomLevel = Number($("#hdn_zoomLevel").val());
    } else{
        zoomLevel = 8.8;
    }
    const mapProp = {
        center: {lat: parseFloat(center[1]), lng: parseFloat(center[0])},
        zoom: zoomLevel,
        zoomControl: true,
        scaleControl: true,
    };

    let map = new google.maps.Map(document.getElementById("gmap"), mapProp);

    map.data.addGeoJson(data);
    map.data.setStyle(function (feature:any) {
        const color = feature.getProperty('fillColor');
        return {
            fillColor: color,
            strokeWeight: 1,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            fillOpacity: 0.35
        };
    });
}

function initGMapPolygon(){
    let map;

    let minX:any, minY:any, maxX:any, maxY:any;

    //function to add points from individual rings
    let addPoints = function(ptsArray:any, data:any) {
        //first spilt the string into individual points
        let pointsData = data.split(",");
        let str = "";
        //iterate over each points data and create a latlong
        //& add it to the cords array
        let len = pointsData.length;
        for (let i = 0; i < len; i++)
        {
            let xy = pointsData[i].trim().split(" ");
            let pt = new google.maps.LatLng(xy[1], xy[0]);
            str = str + ", " + xy[1] + " " + xy[0];
            ptsArray.push(pt);

            if (i === 0){ 
            // if first point 
                minX = maxX = xy[1];
                minY = maxY = xy[0];
            }
            else{
                minX = Math.min(xy[1], minX);
                minY = Math.min(xy[0], minY);
                maxX = Math.max(xy[1], maxX);
                maxY = Math.max(xy[0], maxY);
            }
        }
    }
    let createPoly = function(wkt:any) {
        
        //using regex, we will get the indivudal Rings
        let regex = /\(([^()]+)\)/g; 
        let Rings = [];
        let results;

        while (results = regex.exec(wkt)){
            Rings.push(results[1]);
        }

        //Rings.push(wkt);

        let ptsArray:any = [];
        let polyLen = Rings.length;
        let maxLen = 0;
        let j = 0;

        for (let i = 0; i < polyLen; i++){
            if (maxLen <= Rings[i].length){
                maxLen = Rings[i].length;
                j = i;
            }
        }
    
        addPoints(ptsArray, Rings[j]);

        let poly = new google.maps.Polygon({
            paths: ptsArray,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#1E90FF',
            fillOpacity: 0.35
        });
        return poly;

    };

    let centerLatitude:any, centerLongitude:any;
    wkts.forEach((wkt: any) => {
        const poly = createPoly(wkt);
        centerLatitude = $("#hdnLatitude").val();
        centerLongitude = $("#hdnLongitude").val();

        if(!(centerLatitude && centerLongitude)){
            centerLatitude = ((maxX + minX) / 2);
            centerLongitude = ((maxY + minY) / 2);
        }
            
        let zoomLevel;
        if($("#hdn_zoomLevel").val()){
            zoomLevel = Number($("#hdn_zoomLevel").val());
        } else{
            zoomLevel = 8.8;
        }
        
        const myOptions = {
            zoom: zoomLevel,
            center: new google.maps.LatLng(centerLatitude, centerLongitude),
            zoomControl: true,
            scaleControl: true,
        };

        map = new google.maps.Map($('#gmap')[0], myOptions);
        const bounds = new google.maps.LatLngBounds();
        
        poly.getPath().forEach((path) => {
            bounds.extend(path);
        });
        
        map.fitBounds(bounds);
        poly.setMap(map);

        return poly;
    });
    
}

function getwellKnownText(){
    let franchiseId = $("#hdnFranchiseId").val();
    const apiDomain = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? window.DEFINE_VALUE.JS_API_URL : "https://api.neighborly.com";
    const apiKey = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? window.DEFINE_VALUE.JS_API_KEY : "pneiweb-9pbaOywNKQj853D";
    const digitalorchestrations = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? window.DEFINE_VALUE.JS_DigitalOrchestrations_URL : "digitalorchestrations-api";
    
    const apiUrl = `${apiDomain}/${digitalorchestrations}/v1/public/franchiseGroupOrchestrator/0/${franchiseId}`;
    const transactionID = sessionStorage.getItem('corelationID');
    const sessionID = sessionStorage.getItem('sessionID');
    let correlationId = `correlationId=${transactionID}&sessionId=${sessionID}`;
    let isMapDraw = 0;
    $('.gmap-loader').addClass('loader-wrapper-test loader-ring-test');
    $.ajax({
        type: "GET",
        url: apiUrl,
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'apikey': apiKey,
            'X-Corellation-Id': correlationId
        },
        success: function(result:any, status:any, xhr:any) {
            setTimeout( function(){
                $('.gmap-loader').removeClass('loader-wrapper-test loader-ring-test');
            },1000);
		    let territoryData = result.franchiseTerritory;
            $.each(territoryData.franchiseTerritories, function(key:any, value:any) {
            if ( isMapDraw === 0){
                if(value.franchiseWebLocationId == $("#weblocationId").val() && value.comment.includes("website")){
                    let wktData = value.territoryBoundary.wellKnownText;
                    wkts.push(wktData);
                    mapType = wktData.split(" ")[0].toLowerCase();
                    isMapDraw = 1;
                    centroid = value.territoryBoundary.centroid;
                }
            }
        });

    if (isMapDraw === 1){
        multipleWkts();
    }
},
    error: function(xhr:any, status:any, error:any) {
	 $('.gmap-loader').removeClass('loader-wrapper-test loader-ring-test');
        }
    });
}