import { isNotProd } from "./util";
import { getCachedAPI2 } from "../apiCaching/apiWrapper";
import { apiCaching } from "../config/cachingConfig";
import { $ } from "../third-party-imports";
import {loadGallery} from "./loadGallery";

const apiDomain = isNotProd() ? window.DEFINE_VALUE.JS_API_URL : "https://api.neighborly.com/";
var digitalorchestrations = isNotProd() ? window.DEFINE_VALUE.JS_DigitalOrchestrations_URL : "digitalorchestrations-api";

var photosApi = `${apiDomain}/${digitalorchestrations}/v1/public/photos/images`;
var photosCategoriesApi =  `${apiDomain}/${digitalorchestrations}/v1/public/photos/categories`;
var dbaName = window.location.href.split('/')[3] + "/";

// Get the values of weblocationId and ConceptId
var weblocationId = $("#weblocationId").val() || 0;
var conceptId = $("#conceptId").val() || 0;
var categoryID = 0;
const galleryapi = `${photosApi}/${weblocationId}/${conceptId}/${categoryID}`;
const categoryApi = `${photosCategoriesApi}/${weblocationId}/${conceptId}`;

var galleryCategories:any = [];
var item_order:any = [];
var items:any = 1;
var allCategoryPagination = 0;
let totalCatgory=6;
let records_per_page = 18;
function photoUrlFun(){
    
    let photoUrl:string;  
    photoUrl = "https://www." + $("#brandName").val().replaceAll("-","") + ".com/";
    return photoUrl;
}

let photoDomain = window.location.host.indexOf("www-nei") != -1 ? photoUrlFun() : window.location.origin+'/';
if(photoDomain.indexOf("https://")==-1){
    photoDomain = 'https://'+photoDomain;
}
const category:any = { id: 0, name: '', };
const Pagination =
{
    page: 1,
    size: 0,
    step: 2,
    html: '',
    rem: 0,
    index: 0,
};
var allPhotos:any;
var arraysplit:any = [];
let gallery:any =  document.getElementById('jsImageGallery');
if(gallery){
    
    window.onload = function(){
        $('#photo-gallery .tab-head-text').innerText = "Following are some photo examples:";
        setShareLinks_lightbox_gallery();
        $('.tabbing-panel-section .tabs').removeClass('hidden');
        setTimeout(() => {
            loadFullGallery();    
        },3000);
        
        //set pagination for selected category
        $('#jsCategoryTab #jsCategorySelect').on('change', function(this:any) {    
            category.name = $(this).text();
            if($(this).val() == 1 || $(this).val() == 0){
                category.id = 'All';
            }
            else{
                category.id = $(this).val();
            }
            let aria_sel1:any = false;
            let aria_sel2:any =  true;
            $("#jsCategoryTab button").attr('aria-selected', aria_sel1);
            $("#jsCategoryTab button#category" + category.id).attr('aria-selected', aria_sel2);
            $('.tab-content').attr('hidden', 'hidden');
            $('#categoryTab' + category.id).removeAttr('hidden');
            
            Pagination.page = 1;
            //loadPhotosbyCategoryfromSession(category.id,Pagination.page);

            if (category.id == '0' || category.id == 'All' || category.id == '1') {
                loadAllCategory(allPhotos);
            }
            else {
                loadPhotosbyCategoryfromSession(category.id,Pagination.page);
            } 
        });
        $('.mobile-filter #jsCategorySelect').on('change', function(this:any) {    
            category.name = $(this).text();
            if($(this).val() == 1 || $(this).val() == 0){
                category.id = 'All';
            }
            else{
                category.id = $(this).val();
            }
            let aria_sel1:any = false;
            let aria_sel2:any =  true;
            $("#jsCategoryTab button").attr('aria-selected', aria_sel1);
            $("#jsCategoryTab button#category" + category.id).attr('aria-selected', aria_sel2);
            $('.tab-content').attr('hidden', 'hidden');
            $('#categoryTab' + category.id).removeAttr('hidden');
            
            Pagination.page = 1;
            //loadPhotosbyCategoryfromSession(category.id,Pagination.page);

            
            if (category.id == '0' || category.id == 'All' || category.id == '1') {
                loadAllCategory(allPhotos);
            }
            else {
                loadPhotosbyCategoryfromSession(category.id,Pagination.page);
            } 

        });

        $(document).on('click', '.tabbing-panel-section .pagination-section .page-numbers a', function (this:any,e) {
            e.preventDefault();
            let just_text:any = $(this)
            Pagination.page = Number(just_text.justtext()); 
            
            if (category.id == '0' || category.id == 'All' || category.id == '1') {
                loadAllCategory(allPhotos);
            }
            else {
                loadPhotosbyCategoryfromSession(category.id,Pagination.page);
            } 
            
            scrollUp();        
        });
        $(document).on('click', '.tabbing-panel-section .pagination-section .pagination .next', function (e) {
            e.preventDefault();
            if (Pagination.page < Pagination.size) {
                Pagination.page++;
                var liindex = $('ul.page-numbers li a[aria-current="page"]').parent().index();
                $('.tabbing-panel-section ul.page-numbers li:nth-child(' + (liindex + 2) + ') a').trigger('click');
            }
        });
        $(document).on('click', '.tabbing-panel-section .pagination-section .pagination .prev', function (e) {
            e.preventDefault();
            Pagination.page--;
            var liindex = $('ul.page-numbers li a[aria-current="page"]').parent().index();
            if (liindex == 1) {
                $('ul.page-numbers li:first-child a').trigger('click');
            }
            else {
                $('ul.page-numbers li:nth-child(' + (liindex) + ') a').trigger('click');
            }
            
        });
        $(document).on('click', '.tabbing-panel-section .pagination-section .pagination .first', function (e) {
            e.preventDefault();
            Pagination.page = 1;
            $('.tabbing-panel-section ul.page-numbers li:first-child a').trigger('click');
        });
        $(document).on('click', '.tabbing-panel-section .pagination-section .pagination .last', function (e) {
            e.preventDefault();
            Pagination.page = Pagination.size;
            $('.tabbing-panel-section ul.page-numbers li:last-child a').trigger('click');  
        });
    }
}
function socialWindow_lightbox_gallery(url:any,name:any) {
    $(".social-icons-list a").prop({
        target: "_blank",
        href: url
    })[0].trigger('click');
    
}
function setShareLinks_lightbox_gallery() {
    var pageUrl = "";
    var desc = "";
    $("#a_share_fb_photogallery").on("click", function () {
        pageUrl = decodeURIComponent($("#imageGalleryImage").prop("src"));
        desc = decodeURIComponent($("#imageGalleryTitle").text());
        let urlfb = "https://www.facebook.com/sharer.php?u=" + pageUrl;
        socialWindow_lightbox_gallery(urlfb,"facebook");
    });
    $("#a_share_twitter_photogallery").on("click", function () {
        pageUrl = decodeURIComponent($("#imageGalleryImage").prop("src"));
        desc = decodeURIComponent($("#imageGalleryTitle").text());
        let urltw = "https://twitter.com/intent/tweet?url=" + pageUrl + "&text=" + desc;
        socialWindow_lightbox_gallery(urltw,"twitter");
    });
    $("#a_share_pin_photogallery").on("click", function () {
        pageUrl = decodeURIComponent($("#imageGalleryImage").prop("src"));
        desc = decodeURIComponent($("#imageGalleryTitle").text());
        let urlpin = "http://pinterest.com/pin/create/button/?url=" + pageUrl + "&amp;description=" + desc;
        socialWindow_lightbox_gallery(urlpin,"pinterest");
    });
    $("#a_share_mainlto_photogallery").on("click", function () {
        pageUrl = $("#imageGalleryImage").prop("src");
        desc = decodeURIComponent($("#imageGalleryTitle").text());
        let urlmai = "mailto:?subject="+ desc +"&body="+ pageUrl +"";
        socialWindow_lightbox_gallery(urlmai,"mailto");
    });
    $("#a_share_link_photogallery").on("click", function (e) {
        e.preventDefault();
        pageUrl = $("#imageGalleryImage").prop("src");
        //desc = decodeURIComponent($("#imageGalleryTitle").text());
        let urlImg = pageUrl;
        //socialWindow_lightbox_gallery(urlImg,"link");
        copyToClipboard(urlImg);
    });
}

function copyToClipboard(url: string): void {
    navigator.clipboard.writeText(url)
    .then(() => {
        alert(`${url}\nCopied to Clipboard`);
    })
    .catch(err => {
        console.error('Failed to copy text: ', err);
    });
}


async function loadFullGallery() {
    
    try{
        let response = await getCachedAPI2(galleryapi, apiCaching.PhotoGalleryAPi, true );
        if (response.numFound > 0) {
            $('.tabbing-panel-section .tabs').removeClass('hidden');
            const apiUrl = galleryapi + '?skip=' + response.numFound;
            try{
            let data = await getCachedAPI2(apiUrl, apiCaching.PhotoGalleryAPi, true);
                allPhotos = data.photos; 
                console.log(allPhotos);            
                var items:any = [];
                var categories:any = [];
                $.each(allPhotos, function (i:any, el:any) {
                    items.push('<div><span class="sr-only grouping"></span><a class="thumbnail" href="#" data-image-id="" data-toggle="modal" data-alt="' + el.altText + '" data-title="' + el.longCaption + '" data-image="'+ photoDomain + dbaName +'photos/' + encodeURI(el.imageURL) + '" data-target="#jsImageGallery"><img src="'+ photoDomain +'photos/' + encodeURI(el.thumbnailURL) + '" alt="' + el.altText + '" width="366" height="248" fetchpriority="low" loading="lazy" /><p class="photo-title grey-clr body-text" aria-hidden="true">' + el.shortCaption + '</p></a></div>');                        
                    categories.push({ categoryId: el.categoryId, name: el.categoryName});
                });
                // $('.result-gallery .slider').slick('unslick');
                $('.result-gallery .slider').append(items);
                $('.result-gallery .slider').not('.slick-initialized').slick({
                    infinite: true,
                    dots: true,
                    speed: 300,
                    accessible: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    prevArrow: '<button aria-label="slider previous" class="slider-prev"><span>Prev</span></button>',
                    nextArrow: '<button aria-label="slider next" class="slider-next"><span>Next</span></button>',
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
                //galleryCategories = Array.from(new Set(categories));
                galleryCategories.push({ categoryId: 0, name: "All"});
                var ids = categories.map(({ categoryId}) => categoryId);
                galleryCategories = categories.filter(({ categoryId }, index:any) =>
                !ids.includes(categoryId , index + 1));
                var categorySelect:any = [];
                categorySelect.push('<option value="1" label="All">All</option>');
                $.each(galleryCategories, function (index:any, cat:any) {
                    var tabitem:any = [];
                    item_order.push(cat.name);
                    categorySelect.push('<option value="' + cat.categoryId + '" label="' + cat.name + '">' + cat.name + '</option>');
                    tabitem.push('<div tabindex="0" class="tab-content" role="tabpanel" id="categoryTab' + cat.categoryId + '" aria-labelledby="category' + cat.categoryId + '" hidden=""><div class="container"><h2 class="primary-title primary-theme-clr text-xlg">' + cat.name + '</h2><ul class="row image-gallery-row"></ul></div></div>');
                    $('.tabs .pagination-section').before(tabitem);
                });
                //$('.mobile-filter #jsCategorySelect').append(categorySelect);
                //$('#jsCategoryTab #jsCategorySelect').append(categorySelect);
                
                setTimeout(function () {
                    loadGallery(true, 'a.thumbnail');
                }, 500);
            
                sessionStorage.setItem("photoGallery",JSON.stringify(allPhotos));
                sessionStorage.setItem("numFound",JSON.stringify(data.numFound));
                allPhotos = mapOrder(allPhotos, item_order, 'categoryName');
                sessionStorage.setItem("photoGallery",JSON.stringify(allPhotos));
                splitPhotosArray(allPhotos);
                
                setTimeout(function () {
                    loadAllCategory(allPhotos);
                }, 500);
            }catch(error: any){
                console.error(`Error in fetching photoGallery api response Error message: ${error}`);
            }
        }else {
            $('.tabbing-panel-section .tabs').addClass('hidden');
        }    
    }catch(error: any){
        console.error(`Error in fetching photoGallery api response Error message: ${error}`);
    }
}

jQuery.prototype.justtext = function () {
    return $(this).clone()
        .children()
        .remove()
        .end()
        .text();
};
function scrollUp() {
    $('html, body').animate({
        scrollTop: $(".tabs").offset().top
    }, 500);
}
function loadPagination(categoryid:any, photosCount: any) {  

    var num;
    if(photosCount && photosCount != null){
        if(categoryid == 'All' || categoryid == '1'){
            num = photosCount;
        }
        else{
            num = Math.ceil((photosCount) / 18);
        }
    } else {
        num = 0;
    }
    
    Pagination.size = num;
    Pagination.html = '';
    if (num > 1) {
        if (Pagination.size < Pagination.step * 2 + 3) {
            AddPagination(1, Pagination.size + 1);
        }
        else if (Pagination.page < Pagination.step * 2) {
            AddPagination(1, Pagination.step * 2 + 1);
            setLastPagination();
        }
        else if (Pagination.page > Pagination.size - Pagination.step * 2) {
            Pagination.html += '<li class="page-item"><a class="pagination-item text-sm" href="#"><span class="sr-only">page</span>1</a></li><li class="page-item"><a class="pagination-item text-sm"><span class="sr-only">Expand page</span>[...]</a></li>';
            AddPagination(Pagination.size - Pagination.step * 2, Pagination.size + 1);
        }
        else {
            Pagination.html += '<li class="page-item"><a class="pagination-item text-sm" href="#"><span class="sr-only">page</span>1</a></li><li class="page-item"><a class="pagination-item text-sm"><span class="sr-only">Expand page</span>[...]</a></li>'
            AddPagination(Pagination.page - 1, Pagination.page + Pagination.step);
            setLastPagination();
        }
        $('.tabbing-panel-section .pagination-section .page-numbers').html(Pagination.html);
        $('.tabbing-panel-section .pagination-section').removeClass('hidden');
    }
    else {
        $('.tabbing-panel-section .pagination-section').addClass('hidden');
    }
   
    setCurrentPage();
}
function setCurrentPage() {
    $('.tabbing-panel-section ul.page-numbers a').removeAttr('aria-current');
    $('.tabbing-panel-section ul.page-numbers a').each(function (this:any) {
        if ($(this).text() == 'page' + Pagination.page) {
            $(this).attr('aria-current', 'page');
        }
    })
    
    if (Pagination.page == 1) {
        $('.tabbing-panel-section .pagination').find('.first, .prev').addClass('disabled');
        $('.tabbing-panel-section .pagination').find('.next, .last').removeClass('disabled');
    }
    else if (Pagination.page == Pagination.size) {
        $('.tabbing-panel-section .pagination').find('.first, .prev').removeClass('disabled');
        $('.tabbing-panel-section .pagination').find('.next, .last').addClass('disabled');
    }
    else {
        $('.tabbing-panel-section .pagination').find('.first, .prev, .next, .last').removeClass('disabled');
    }
}
function setLastPagination() {
    Pagination.html += '<li class="page-item"><a class="pagination-item text-sm"><span class="sr-only">Expand page</span>[...]</a></li><li class="page-item"><a class="pagination-item text-sm" href="#"><span class="sr-only">page</span>' + Pagination.size + '</a></li>'; //<li class="page-item"><a class="pagination-item text-sm"><span class="sr-only">page</span>' + (Pagination.size - 1) + '</a></li>
}
function AddPagination(start:any, finish:any) {
    for (var i = start; i < finish; i++) {
        Pagination.html += '<li class="page-item"><a class="pagination-item text-sm" href="#"><span class="sr-only">page</span>' + i + '</a></li>';
    }
}
function mapOrder(array:any, order:any, key:any) {
    array.sort(function (a:any, b:any) {
        var A = a[key], B = b[key];
        if (order.indexOf(B) > order.indexOf(A)) {
            return -1;
        } else {
            return 1;
        }
    });
    return array;
}
function loadAllCategory(arrPhotos:any) {
    
    items = 1;
    var taball = [];
    Pagination.index = Pagination.page == 1 ? 0 : arraysplit[Pagination.page - 1];    
    var catName = "";
    var divOpen = true;
    var rowCnt = 1;
    var TotalrowCnt = 0;
         
    for (var o = Pagination.index; o < arrPhotos.length; o++) {
        if (TotalrowCnt <= totalCatgory || (TotalrowCnt == totalCatgory && rowCnt < 3 && catName === arrPhotos[o].categoryName)) {
            divOpen = true;
            if (catName === "" || catName !== arrPhotos[o].categoryName) {
                if (catName !== "") {
                    taball.push('</ul></div>');
                    divOpen = false;
                }
                taball.push('<div class="container"><h2 class="primary-title primary-theme-clr text-xlg">' + arrPhotos[o].categoryName + '</h2><ul class="row image-gallery-row">');
            }
            taball.push('<li class="col-md-4 col-sm-6 image-wrap"><a class="thumbnail" href="#" data-image-id="" data-toggle="modal" data-alt="' + arrPhotos[o].altText + '" data-title="' + arrPhotos[o].longCaption + '" data-image="'+ photoDomain + dbaName +'photos/' + encodeURI(arrPhotos[o].imageURL) + '" data-target="#jsImageGallery"> <img class="" src="'+photoDomain+'photos/' + encodeURI(arrPhotos[o].thumbnailURL) + '" alt="' + arrPhotos[o].altText + '" width="376" height="255" fetchpriority="low" loading="lazy"> <p class="dark-grey-primary-clr medium-font img-caption" aria-hidden="true">' + arrPhotos[o].shortCaption + '</p></a></li>');
            if (rowCnt == 3 || catName !== arrPhotos[o].categoryName) {
                TotalrowCnt++;
                rowCnt = 0;
            }
            rowCnt++;
            catName = arrPhotos[o].categoryName;            
        }
    }
    if (divOpen === true) {
        taball.push('</ul></div>');
    }
    $('#categoryTabAll').html(taball.join(''));
    setTimeout(function () {
        loadGallery(true, 'a.thumbnail');
    }, 500);
    allCategoryPagination = arraysplit.length;
    loadPagination("All", arraysplit.length);
}
function splitPhotosArray(arrPhotos:any) {
    var oldIndex = 0;
    var catName = "";
    var rowCnt = 0;
    var TotalrowCnt = 0;
    for (var o = 0; o < arrPhotos.length; o++) {
        if (catName == "" || catName === arrPhotos[o].categoryName) {
            if (rowCnt >= 3) {
                TotalrowCnt++;
                rowCnt = 0;
            }
            if (TotalrowCnt >= 6) {
                arraysplit.push(oldIndex);
                TotalrowCnt = 0;
                oldIndex = o - 1;
                rowCnt = 0;
            }
        }
        else {            
            TotalrowCnt++;
            rowCnt = 0;
        }
        rowCnt++;
        catName = arrPhotos[o].categoryName;
    }
    if (rowCnt != 0) {
        arraysplit.push((oldIndex + 1));        
    }    
}

function loadPhotosbyCategoryfromSession(categoryid:any,page:any){
    
    if (categoryid != 'All' || categoryid!= '1' || categoryid!= '0') {
        let photos = JSON.parse(sessionStorage.getItem("photoGallery"));
        let fillterPhotos= photos.filter((photo:any)=>{
            return photo.categoryId===categoryid;
        });
        let photoitems:any = [];
        let numFound = fillterPhotos.length;
        loadPagination(categoryid, numFound);
        changePage(fillterPhotos,page,photoitems);
        $('#categoryTab' + categoryid + ' ul').html(photoitems);
            setTimeout(function () {
            loadGallery(true, 'a.thumbnail');                
        }, 1500);

    }
    else{
        loadPagination("All", allCategoryPagination);
    }
}
function changePage(fillterPhotos:any,page:any,photoitems:any)
{
    // Validate page
    if (page < 1) page = 1;
    if (page > numPages(fillterPhotos)) page = numPages(fillterPhotos);

    for (var i = (page-1) * records_per_page; i < (page * records_per_page) && i < fillterPhotos.length; i++) {
        photoitems.push('<li class="col-md-4 col-sm-6 image-wrap"><a class="thumbnail" href="#" data-image-id="" data-toggle="modal" data-alt="' + fillterPhotos[i].altText + '" data-title="' + fillterPhotos[i].longCaption + '" data-image="'+ photoDomain + dbaName +'photos/' + encodeURI(fillterPhotos[i].imageURL) + '" data-target="#jsImageGallery"> <img class="" src="'+photoDomain+'photos/' + encodeURI(fillterPhotos[i].thumbnailURL) + '" alt="' + fillterPhotos[i].altText + '" width="376" height="255" fetchpriority="low" loading="lazy"> <p class="dark-grey-primary-clr medium-font img-caption" aria-hidden="true">' + fillterPhotos[i].shortCaption + '</p></a></li>');
    }
}

function numPages(fillterPhotos:any)
{
    return Math.ceil(fillterPhotos.length / records_per_page);
}
